import React from 'react'
import './Shapes.css'

import Shape from '../Shape/Shape'

const shapes = {
    rock: {
        id: 1,
        src: 'img/raised-fist.png',
        alt: 'closed fist'
    },
    paper: {
        id: 2,
        src: 'img/raised-hand.png',
        alt: 'flat hand'
    },
    scissors: {
        id: 3,
        src: 'img/victory-hand.png',
        alt: 'fist with the index finger and middle finger extended'

    }
}

class Shapes extends React.Component {
    createShapes() {
        const keys = Object.keys(shapes)
        return keys.map(element => {
            return <Shape key={shapes[element].id} src={shapes[element].src} alt={shapes[element].alt} />
        })
    }

    render() {
        return (
            <div className='container'>
                {this.createShapes()}
            </div>
        )
    }
}

export default Shapes;