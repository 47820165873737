import React from 'react'
import './Shape.css'

class Shape extends React.Component {
    render() {
        return (
            <img className='item' src={this.props.src} alt={this.props.alt} />
        )
    }
}

export default Shape