import React from 'react'
import './App.css'
import Shapes from '../Shapes/Shapes'

function App() {
  return (
    <div className="App">
      <Shapes />
    </div>
  );
}

export default App;
